import { LOAD_TAXONOMY, LOAD_TAXONOMY_SUCCESS, LOAD_TAXONOMY_FAILURE } from '../actions/actionTypes'

const initialState = {
  taxonomy: [],
  isLoading: false,
  hasError: false,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_TAXONOMY: {
      return {
        taxonomy: [],
        isLoading: true,
        hasError: false,
      }
    }
    case LOAD_TAXONOMY_SUCCESS: {
      return {
        taxonomy: action.payload,
        isLoading: false,
        hasError: false,
      }
    }
    case LOAD_TAXONOMY_FAILURE: {
      return {
        taxonomy: [],
        isLoading: false,
        hasError: true,
      }
    }
    default: {
      return state
    }
  }
}
