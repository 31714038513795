import 'isomorphic-unfetch'
import { call, put, takeLatest } from 'redux-saga/effects'
import { LOAD_APP, LOAD_CMS_DATA, LOAD_HOMEPAGE_DATA } from '../actions/actionTypes'
import { loadCmsDataRequest, loadCmsDataFailure, loadCmsDataSuccess, resetCmsData } from '../actions/cmsAppActions'
import {
  loadHomepageDataRequest,
  loadHomepageDataSuccess,
  loadHomepageDataFailure,
  resetHomepageData,
} from '../actions/homepageAppActions'
import { fetchCmsApi } from '../api/cmsApi'
import fetchHomepageApi from '../api/homepageApi'

// Sagas
export function* loadApp() {
  yield console.debug('UI-Global - App Loaded')
}

export function* loadCmsDataSaga(cookies, action) {
  try {
    if (action?.resetData) yield put(resetCmsData())

    yield put(loadCmsDataRequest())
    const cmsResponse = yield call(fetchCmsApi, action, cookies)
    yield put(loadCmsDataSuccess(cmsResponse, action.location))
  } catch (err) {
    yield put(loadCmsDataFailure(err))
  }
}

export function* loadHomepageDataSaga(cookies, action) {
  try {
    if (action?.resetData) yield put(resetHomepageData())

    yield put(loadHomepageDataRequest())
    const homepageResponse = yield call(fetchHomepageApi, action, cookies)

    yield put(loadHomepageDataSuccess(homepageResponse))
  } catch (err) {
    yield put(loadHomepageDataFailure(err))
  }
}

// Watchers
export function* watchLoadApp() {
  yield takeLatest(LOAD_APP, loadApp)
}

export function* watchLoadData(cookies) {
  yield takeLatest(LOAD_CMS_DATA, loadCmsDataSaga, cookies)
  yield takeLatest(LOAD_HOMEPAGE_DATA, loadHomepageDataSaga, cookies)
}
