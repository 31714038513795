import styled from 'styled-components'

const AppWrapper = styled.div`
  display: block;

  @supports (display: grid) {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
`

const AppContent = styled.div`
  padding: 0;
  flex: 1;
  min-height: 50vh;
  display: block;
`

const PropbarContainer = styled.div`
  margin-bottom: 15px;
`

export { AppWrapper, AppContent, PropbarContainer }
