import { DYSON_STUB_COOKIE_NAME } from '../../stubs/_config'

const getHeaders = (cookies = {}) => {
  if (cookies[DYSON_STUB_COOKIE_NAME]) {
    return {
      headers: {
        Cookie: `${DYSON_STUB_COOKIE_NAME}=${cookies[DYSON_STUB_COOKIE_NAME]}`,
      },
    }
  }
  return {}
}

export default getHeaders
