/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import { gethostPath } from '../../helpers/ContentHelper'
import PromotionPanel from '../PromotionPanel/PromotionPanel'

const FeaturedProducts = () => {
  const [deliveryData, setDeliveryData] = useState()
  const [productsData, setProductsData] = useState()
  const [gettingData, nowGettingData] = useState(false)
  const [hasError, setHasError] = useState(false)

  const featuredProductsData = {
    error: hasError,
    loading: !productsData,
    products: productsData,
    pageBeacon: deliveryData && deliveryData.PageBeacon,
  }

  const catalogId = () =>
    process.env.CITRUS_ENV === 'production'
      ? 'f5a9270b-e5b9-42bb-80ab-43b342d0a7e2'
      : 'c0de8909-5a69-44ad-aab7-b79631564c2d'

  const fetchProductData = async () => {
    if (!deliveryData?.ads) {
      // If no products, then set empty array
      setProductsData([])
      return
    }
    const featuredSKUs = deliveryData.ads.map((product) => product.gtin)
    Promise.all(featuredSKUs.map((product) => fetch(`${gethostPath()}global-api/products/partNumber=${product}`)))
      .then((groupedResponse) =>
        Promise.all(
          groupedResponse.map((response) =>
            response
              .json()
              .then((dataTest) =>
                dataTest?.data?.data ? { ...dataTest.data.data, included: dataTest.data.included } : null,
              ),
          ),
        ),
      )
      .then((finalData) => {
        const citrusProds = deliveryData.ads
        const data = []
        finalData.forEach((argosProd, index) => {
          if (!argosProd) return
          data.push({
            ...argosProd,
            attributes: {
              ...{ ...argosProd.attributes },
              ...{ tracking: citrusProds[index] },
            },
          })
        })
        setProductsData(data)
      })
      .catch((error) => {
        console.error(error)
        setProductsData([])
      })
  }

  const fetchFeaturedProducts = async () => {
    const AMCV_MATCH = /AMCV_[0-9A-Z\%]+AdobeOrg=([0-9a-zA-Z\%\-\_]+)/g.exec(document.cookie)
    const S_VI_MATCH = /s_vi=([0-9a-zA-Z\%\-\|\[\]]+)/g.exec(document.cookie)
    const USER_ID = S_VI_MATCH ? S_VI_MATCH[1] : AMCV_MATCH ? AMCV_MATCH[1] : null

    const URL = `${gethostPath()}citrus-api/`

    const fetchDeliveryData = async () => {
      try {
        const res = await fetch(URL, {
          method: 'POST',
          body: JSON.stringify({
            catalogId: catalogId(),
            maxNumberOfAds: 10,
            placement: 'home-desktop',
            sessionId: decodeURIComponent(USER_ID),
          }),
        })
        const resData = await res.json()
        setDeliveryData(resData)
      } catch (err) {
        setHasError(true)
      }
    }
    fetchDeliveryData()
  }

  const getData = () => {
    if (!gettingData) {
      nowGettingData(true)
      fetchFeaturedProducts()
    }
  }

  // Fetch product data once delivery data is populated
  deliveryData && typeof productsData === 'undefined' && fetchProductData()

  const title = !deliveryData ? '' : "We think you'll like..."

  const tracking = {
    pageCategory: 'HP',
    mvts: deliveryData?.meta?.mvts[0]?.variantId,
    onLoad: {
      eventName: 'hp_recommendationsLoaded',
      eventAction: 'Recommendations Loaded',
    },
    onView: {
      eventName: 'hp_recommendationsViewed',
      eventAction: 'Recommendations Viewed',
    },
    onSlideChange: {
      eventName: 'hp_recommendationsNewPageViewed',
      eventAction: 'Recommendations New Page Viewed',
    },
  }

  return (
    <InView onChange={(inView) => inView && !deliveryData && getData()}>
      <PromotionPanel
        citrusEnabled
        data={featuredProductsData}
        dataTest='we-think-youll-like'
        tracking={tracking}
        title={title}
        source='monetisation'
        section='middleSlider'
      />
    </InView>
  )
}

export default FeaturedProducts
