import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import * as HomepageStyled from '../HomepageComponents/HomepageComponents.styles'
import * as Styled from './PageSkeleton.styles'

const PageSkeleton = ({ page }) => {
  const skeletonContent = []
  const speed = 1
  const backgroundColor = '#f3f3f3'
  const foregroundColor = '#ecebeb'
  const defaultLoader = (
    <Styled.DefaultLoader
      height={100}
      width={50}
      speed={speed}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      preserveAspectRatio='none'
      uniqueKey={`${page}-content-loader`}>
      <rect x='0' y='0' rx='0' ry='0' width='1000' height='600' />
    </Styled.DefaultLoader>
  )

  if (page === 'homepage') {
    skeletonContent.push(
      <div key={page} className='xs-row xs-12--negative'>
        <HomepageStyled.Container columns={4}>
          {[1, 2, 3, 4].map((comp, index) => (
            <HomepageStyled.Component index={index + 1} key={`homepage-skeleton-${index}`}>
              <HomepageStyled.ImageContainer>{defaultLoader}</HomepageStyled.ImageContainer>
            </HomepageStyled.Component>
          ))}
        </HomepageStyled.Container>
      </div>,
    )
  }
  if (page === 'cms') {
    skeletonContent.push(
      <div key={page}>
        <Styled.HeroLoader
          height={100}
          width={100}
          speed={speed}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          preserveAspectRatio='none'>
          <rect x='0' y='0' rx='0' ry='0' width='100%' height='350' />
        </Styled.HeroLoader>
        <ContentLoader
          height={87}
          width={1000}
          speed={speed}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          className='md-hidden'
          preserveAspectRatio='none'>
          <rect x='0' y='0' rx='0' ry='0' width='350' height='70' />
          <rect x='0' y='80' rx='0' ry='0' width='1000' height='5' />
        </ContentLoader>
        <ContentLoader
          height={1000}
          width={1000}
          speed={speed}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          className='md-hidden'
          preserveAspectRatio='none'>
          <rect x='0' y='0' rx='0' ry='0' width='1000' height='350' />
          <rect x='0' y='380' rx='0' ry='0' width='1000' height='350' />
          <rect x='0' y='760' rx='0' ry='0' width='1000' height='350' />
        </ContentLoader>
        <ContentLoader
          height={72}
          width={1000}
          speed={speed}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          className='xs-hidden md-block'
          preserveAspectRatio='none'>
          <rect x='0' y='0' rx='0' ry='0' width='150' height='30' />
        </ContentLoader>
        <ContentLoader
          height={360}
          width='100%'
          speed={speed}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          className='xs-hidden md-block'
          preserveAspectRatio='none'>
          <rect x='0' y='0' rx='0' ry='0' width='32%' height='250' />
          <rect x='34%' y='0' rx='0' ry='0' width='32%' height='250' />
          <rect x='68%' y='0' rx='0' ry='0' width='32%' height='250' />

          <rect x='0' y='270' rx='0' ry='0' width='150' height='30' />
          <rect x='34%' y='270' rx='0' ry='0' width='150' height='30' />
          <rect x='68%' y='270' rx='0' ry='0' width='150' height='30' />
        </ContentLoader>
      </div>,
    )
  }

  if (page === 'taxonomy') {
    skeletonContent.push(
      <div className='xs-row' key={page}>
        <div className='xs-12--none'>
          <Styled.TaxonomyLoader
            height={75}
            width={214}
            speed={2}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}>
            <rect x='0' y='8' rx='3' ry='3' width='200' height='30' />
            <rect x='0' y='46' rx='3' ry='3' width='85' height='16' />
          </Styled.TaxonomyLoader>
        </div>
        <div className='xs-12--none sm-4--none md-3--none'>
          <Styled.TaxonomyLoader
            height={82}
            width={214}
            speed={2}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}>
            <rect x='0' y='0' rx='3' ry='3' width='120' height='18' />
            <rect x='0' y='28' rx='0' ry='0' width='190' height='1' />
            <rect x='0' y='38' rx='3' ry='3' width='150' height='16' />
            <rect x='0' y='58' rx='3' ry='3' width='120' height='16' />
          </Styled.TaxonomyLoader>
        </div>
        <div className='xs-12--none sm-4--none md-3--none'>
          <Styled.TaxonomyLoader
            height={82}
            width={214}
            speed={2}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}>
            <rect x='0' y='0' rx='3' ry='3' width='94' height='18' />
            <rect x='0' y='28' rx='0' ry='0' width='190' height='1' />
            <rect x='0' y='38' rx='3' ry='3' width='150' height='16' />
            <rect x='0' y='58' rx='3' ry='3' width='120' height='16' />
          </Styled.TaxonomyLoader>
        </div>
        <div className='xs-12--none sm-4--none md-3--none'>
          <Styled.TaxonomyLoader
            height={82}
            width={214}
            speed={2}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}>
            <rect x='0' y='0' rx='3' ry='3' width='94' height='18' />
            <rect x='0' y='28' rx='0' ry='0' width='190' height='1' />
            <rect x='0' y='38' rx='3' ry='3' width='150' height='16' />
            <rect x='0' y='58' rx='3' ry='3' width='120' height='16' />
          </Styled.TaxonomyLoader>
        </div>
        <div className='xs-12--none sm-4--none md-3--none'>
          <Styled.TaxonomyLoader
            height={82}
            width={214}
            speed={2}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}>
            <rect x='0' y='0' rx='3' ry='3' width='94' height='18' />
            <rect x='0' y='28' rx='0' ry='0' width='190' height='1' />
            <rect x='0' y='38' rx='3' ry='3' width='150' height='16' />
            <rect x='0' y='58' rx='3' ry='3' width='120' height='16' />
          </Styled.TaxonomyLoader>
        </div>
      </div>,
    )
  }

  return (
    <Styled.Skeleton page={page} className='container'>
      {skeletonContent}
    </Styled.Skeleton>
  )
}

PageSkeleton.propTypes = {
  page: PropTypes.string.isRequired,
}

export default PageSkeleton
