import { canUseDom } from '@sainsburys-tech/boltui-utils'
import { gethostPath } from '../helpers/ContentHelper'
import getHeaders from '../helpers/getHeaders'

async function homepageApi(action, cookies = {}) {
  const { historyData } = action

  if (canUseDom() && Object.keys(historyData).length) {
    return { data: historyData, status: 200 }
  }

  const url = `${gethostPath()}global-api/homepage/cms`
  const res = await fetch(url, getHeaders(cookies))

  let homepageData = {}
  try {
    homepageData = await res.json()
  } catch (err) {
    homepageData = {}
  }

  const data = {
    meta: {
      title: homepageData.meta.title,
      description: homepageData.meta.description,
      seoFooter: homepageData.meta.seoFooter,
    },
    data: homepageData.data,
  }

  const resObject = {
    data,
    status: res.status,
    statusText: res.statusText,
  }

  return resObject
}

export default homepageApi
