import { canUseDom } from '@sainsburys-tech/boltui-utils'
import { formatToTemplateResponse, gethostPath } from '../helpers/ContentHelper'
import { getCleanPath, isMaskedHabitatPath, isHomepagePath } from '../helpers/pathCheck'
import { ARGOS_CARD_COOKIE, isHomepageTestVariant } from '../helpers/abTestHelper'
import getHeaders from '../helpers/getHeaders'

const urlFormat = (fullLocation, variantTests = []) => {
  const path = fullLocation.split('/')[1] && fullLocation.split('/')[1]

  // e.g. /inspiration -> /features/inspiration
  if (process.env.BRAND === 'habitat' && path && isMaskedHabitatPath(`/${path}`)) {
    return `/features${fullLocation.split('?')[0]}`
  }

  // help page magnolia point actually is `/features/help/...`
  if (path && path === 'help') {
    if (fullLocation.substr(-1) === '/') fullLocation = fullLocation.slice(0, -1)
    const helpLocation = fullLocation
      .split('/')
      .slice(2)
      .join('/')
    return `/features/${helpLocation}`
  }

  // a/b tests
  let variantUrl = ''
  variantTests.forEach((test) => {
    const { testName, testVal } = test

    // ARGOS CARD A/B Test
    if (testName === ARGOS_CARD_COOKIE && fullLocation.split('/')[2] && fullLocation.split('/')[2] === 'variants') {
      variantUrl = `/events/variants/${testVal}`
    }
  })
  if (variantUrl) return variantUrl

  // default cms path
  return fullLocation.split('?')[0]
}

async function fetchCmsApi(action, cookies = {}) {
  const { historyData, location, timestamp, variantTests } = action

  // if data is already in state
  const apiLocation = getCleanPath(location)

  const canUseHistoryState = canUseDom() && historyData && historyData[apiLocation]
  if (canUseHistoryState) {
    return { data: historyData[apiLocation], status: 200 }
  }

  const url = `${gethostPath()}global-api/cms`
  const pagePath = urlFormat(location, variantTests)

  const params = new URLSearchParams()

  if (timestamp) {
    params.append('for_date', timestamp)
  }

  if (isHomepagePath(pagePath) && isHomepageTestVariant(variantTests)) {
    params.append('variant', '1') // get variant homepage content
  }

  const urlParams = params.toString() ? `?${params.toString()}` : ''

  const fullUrl = `${url}${pagePath}${urlParams}`
  const res = await fetch(fullUrl, getHeaders(cookies))
  let data
  try {
    data = await res.json()
  } catch (err) {
    data = {}
  }

  const resObject = {
    data: !data?.template ? formatToTemplateResponse(data) : data,
    status: res.status,
    statusText: res.statusText,
  }

  return resObject
}

export { urlFormat, fetchCmsApi }
