const mock = () => {}

const Logger = () => {}

Logger.prototype.trace = mock
Logger.prototype.debug = mock
Logger.prototype.info = mock
Logger.prototype.warn = mock
Logger.prototype.error = mock
Logger.prototype.fatal = mock
Logger.prototype.addStream = mock
Logger.prototype.addSerializers = mock
Logger.prototype.child = mock
Logger.prototype.level = mock
Logger.prototype.levels = mock

const TRACE = 'NO_OP'
const DEBUG = 'NO_OP'
const INFO = 'NO_OP'
const WARN = 'NO_OP'
const ERROR = 'NO_OP'
const FATAL = 'NO_OP'
const resolveLevel = mock
const levelFromName = mock
const nameFromLevel = mock

const VERSION = 'NO_OP'
const LOG_VERSION = 'NO_OP'

const createLogger = (options) => new Logger(options)
const safeCycles = mock
const ConsoleFormattedStream = () => {}
const ConsoleRawStream = () => {}

export default {
  Logger,
  TRACE,
  DEBUG,
  INFO,
  WARN,
  ERROR,
  FATAL,
  resolveLevel,
  levelFromName,
  nameFromLevel,
  VERSION,
  LOG_VERSION,
  createLogger,
  safeCycles,
  ConsoleFormattedStream,
  ConsoleRawStream,
}
