import { canUseDom } from '@sainsburys-tech/boltui-utils'
import { getCleanPath } from '../helpers/pathCheck'

import {
  LOAD_CMS_DATA_SUCCESS,
  LOAD_CMS_DATA_FAILURE,
  LOAD_CMS_DATA_REQUEST,
  RESET_CMS_DATA,
} from '../actions/actionTypes'
import ExpressLogger from '../utils/Logger'

const cmsInitialState = {
  cmsData: [],
  isLoading: false,
  hasError: false,
  location: '',
  historyData: {},
  argosCardVariant: '',
}

export default (state = cmsInitialState, action = {}) => {
  switch (action.type) {
    case LOAD_CMS_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    }

    case RESET_CMS_DATA: {
      const historyData = state.historyData || {}

      if (state.cmsData && state.cmsData.resettingData !== true && state.location && canUseDom()) {
        const apiLocation = getCleanPath(state.location)
        // check if key / location data is already present
        if (!historyData[apiLocation]) {
          Object.assign(historyData, {
            [apiLocation]: state.cmsData,
          })
        }
      }

      return {
        ...state,
        cmsData: { resettingData: true },
        historyData,
        isLoading: true,
        hasError: false,
      }
    }

    case LOAD_CMS_DATA_SUCCESS: {
      const { cmsResponse, location } = action
      const { data, status } = cmsResponse
      const isLoading = false

      // invalid cms template
      const invalidTemplate = !data || (!data.template?.data && !data.data)
      if (invalidTemplate) {
        const logInfo = {
          name: 'ui-global',
          url: location,
          httpStatusCode: status,
          env: process.env.NODE_ENV,
          cmsEnv: process.env.CMS_API_ENV,
        }
        ExpressLogger('error', 'apiLogger', logInfo, 'Magnolia Content API response')

        return {
          ...state,
          cmsData: [],
          invalidData: true,
          hasError: true,
          isLoading,
        }
      }

      return {
        ...state,
        hasError: false,
        isLoading,
        location,
        cmsData: data,
      }
    }

    case LOAD_CMS_DATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    }
    default: {
      return state
    }
  }
}

export const getData = (state) => state.app.data
export const getLoading = (state) => state.app.isLoading
