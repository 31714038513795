import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { hydrate } from 'react-dom'
import Loadable from 'react-loadable'
import { createBrowserHistory } from 'history'
import { AppContainer } from 'react-hot-loader'
import { canUseDom } from '@sainsburys-tech/boltui-utils'

import rootSaga from './sagas/sagaIndex'
import configureStore from './store/configureStore'
import App from './app/App'
import { isArgos } from './helpers/brandHelper'

const isNotDevelopment = process.env.NODE_ENV !== 'development'

// Add to temporarily remove service workers for Safari 17 and above
const isSafari17andAbove = () => {
  const ua = navigator.userAgent
  const isSafari = ua.indexOf('Safari') !== -1
  const version = ua.substring(ua.indexOf('Version/') + 8)
  const majorVersion = parseInt(version, 10)

  return isSafari && majorVersion >= 17
}

// Register Service Worker
if (isArgos() && 'serviceWorker' in navigator && !isSafari17andAbove()) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js', {
        scope: '/',
      })
      .then((registration) => {
        console.warn('SW registered: ', registration)
      })
      .catch((registrationError) => {
        console.error('SW registration failed: ', registrationError)
      })
  })
}

const browserHistory = createBrowserHistory()

const dest = document.getElementById('content')

/* eslint-disable no-underscore-dangle */
const store = configureStore(browserHistory, window.__data)
if (isNotDevelopment) {
  delete window.__data
}
/* eslint-enable no-underscore-dangle */

// Scroll to top of page on route change
browserHistory.listen(() => {
  if (canUseDom()) window.scrollTo(0, 0)
})

store.runSaga(rootSaga)

const renderApp = (Component) => {
  // Use Loadable to preload any modules we need for initial render
  Loadable.preloadReady().then(() => {
    const app = (
      <AppContainer>
        <Provider store={store}>
          <ConnectedRouter history={browserHistory}>
            <Component />
          </ConnectedRouter>
        </Provider>
      </AppContainer>
    )
    hydrate(app, dest)
  })
}

renderApp(App)

// Enable hot module reloading
if (module.hot) {
  module.hot.accept('./app/App', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./app/App').default
    renderApp(NextApp)
  })
}
