import { isArgos, isHabitat } from './brandHelper'

const maskedHabitatRoutes = ['help', 'inspiration', 'shop-by-room', 'stores', 'events']

const isHelpPath = (path) => {
  if (!path) return null
  const locationRegex = /\/(.*?)\//
  const getPageType = locationRegex.exec(path)
  return getPageType && getPageType[1] === 'help'
}

const isMaskedHabitatPath = (path) => {
  if (!path) return null
  return maskedHabitatRoutes.some((route) => path.startsWith(`/${route}`) || path.startsWith(`/features/${route}`))
}

const isHomepagePath = (path) => path && path === '/'
const isHabitatHomepagePath = (path) => isHomepagePath(path) && isHabitat()
const isArgosHomepagePath = (path) => isHomepagePath(path) && isArgos()
const isReviewsPath = (path) => (path && path.startsWith('/reviews')) || false
const isReviewsArgosCard = (path) => (path && path.split('reviews/')[1] === 'argos-card') || false

const isCmsPath = (path) => {
  if (!path) return null
  if (isHomepagePath(path)) return true
  const locationRegex = /\/(.*?)\//
  const getPageType = locationRegex.exec(path)
  return getPageType && ['events', 'brands', 'features'].indexOf(getPageType[1]) !== -1
}
const isMagnoliaApp = (path) => isCmsPath(path) || isHelpPath(path) || isMaskedHabitatPath(path)

const getCleanPath = (path) =>
  path
    .substr(1)
    .split('?')[0]
    .replace(/\//g, '-')

const getHabitatCanonicalPath = (path) => (isMaskedHabitatPath(path) ? path.replace('/features', '') : path)

const getCmsPathPartsAsArray = (path) => {
  const arr = path.split('/')
  arr.shift()
  return arr.length === 2 ? arr : null
}

export {
  maskedHabitatRoutes,
  isCmsPath,
  isHomepagePath,
  isArgosHomepagePath,
  isHabitatHomepagePath,
  isMaskedHabitatPath,
  isReviewsPath,
  isHelpPath,
  isMagnoliaApp,
  isReviewsArgosCard,
  getCleanPath,
  getHabitatCanonicalPath,
  getCmsPathPartsAsArray,
}
