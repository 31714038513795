import {
  LOAD_HOMEPAGE_DATA,
  LOAD_HOMEPAGE_DATA_REQUEST,
  LOAD_HOMEPAGE_DATA_SUCCESS,
  LOAD_HOMEPAGE_DATA_FAILURE,
  RESET_HOMEPAGE_DATA,
} from './actionTypes'

export const loadHomepageData = ({ resetData, historyData }) => {
  return {
    type: LOAD_HOMEPAGE_DATA,
    resetData,
    historyData,
  }
}

export const loadHomepageDataRequest = () => ({
  type: LOAD_HOMEPAGE_DATA_REQUEST,
})

export const loadHomepageDataSuccess = (homepageResponse, recommendationsResponse) => ({
  type: LOAD_HOMEPAGE_DATA_SUCCESS,
  homepageResponse,
  recommendationsResponse,
})

export const loadHomepageDataFailure = () => ({
  type: LOAD_HOMEPAGE_DATA_FAILURE,
})

export const resetHomepageData = () => ({
  type: RESET_HOMEPAGE_DATA,
})
