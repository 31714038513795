import styled from 'styled-components'
import { Alert } from '@sainsburys-tech/bolt'

const StoreLocator = styled.div`
  padding-bottom: 30px;
`

const Wrapper = styled.div`
  background: ${(props) => props.theme.color.palette['blue-40']};
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.color.monochrome.white};
  flex-direction: column;
  margin: 40px -15px 20px;
  width: calc(100% + 30px);
  @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
    flex-direction: row;
    margin: 40px auto 20px auto;
    width: 100%;
  }
`
const StoreHeading = styled.h4`
  && {
    margin: 0 0 25px 0;
    @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
      margin: 0 15px 0 0;
    }
  }
`

const StoreForm = styled.form`
  position: relative;
`

const StoreInput = styled.input`
  border: 1px solid ${(props) => props.theme.color.monochrome.white};
  border-radius: 3px 0 0 3px;
  height: 44px;
  padding: 8px 100px 8px 16px;
  width: 350px;
  font-size: 1rem;
`

const StoreButton = styled.button`
  position: absolute;
  border: 1px solid ${(props) => props.theme.color.monochrome.white};
  font-weight: bold;
  color: ${(props) => props.theme.color.monochrome.white};
  background: ${(props) => props.theme.color.palette['blue-40']};
  height: 44px;
  width: 100px;
  border-radius: 0 3px 3px 0;
  right: 0;
  top: 0;
  font-size: 1rem;
`

const StoreError = styled(Alert)`
  max-width: 400px;
  margin: 0 auto 15px;
`
export { StoreLocator, Wrapper, StoreHeading, StoreForm, StoreInput, StoreButton, StoreError }
