import { LOAD_REVIEWS_REQUEST } from '../actions/actionTypes'

const reviewsInitialState = {
  isLoading: false,
  hasError: false,
}

export default (state = reviewsInitialState, action = {}) => {
  switch (action.type) {
    case LOAD_REVIEWS_REQUEST: {
      return {
        ...state,
        isLoading: action.isLoading,
        hasError: false,
      }
    }
    default: {
      return state
    }
  }
}
