import React, { useState, useRef } from 'react'
import fetch from 'isomorphic-unfetch'
import { EmailIcon } from '@sainsburys-tech/bolt-icons'
import ContentLoader from 'react-content-loader'
import { gethostPath } from '../../helpers/ContentHelper'
import isValidEmail from '../../helpers/isValidEmail'

import * as Styled from './EmailForm.styles'

const EmailForm = () => {
  const emailInputRef = useRef(null)

  const [isLoading, setLoading] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [isInvalidEmail, setInvalidEmail] = useState(false)

  const sendEmailService = (email) => {
    fetch(`${gethostPath()}global-api/email/address=${email}`).then((res) => {
      if (res.status === 200) setSuccess(true)
      setLoading(false)
    })
  }

  const submitEmail = (event) => {
    event.preventDefault()
    setInvalidEmail(false)
    const emailInput = emailInputRef.current
    if (isValidEmail(emailInput.value)) {
      setLoading(true)
      sendEmailService(emailInput.value)
    } else {
      emailInput.focus()
      setInvalidEmail(true)
    }
  }

  const contentLoaderProps = {
    speed: 1,
    backgroundColor: '#f3f3f3',
    foregroundColor: '#ecebeb',
    preserveAspectRatio: 'none',
  }
  return (
    <>
      <Styled.FormWrapper data-test='email-offers-container'>
        <EmailIcon height={42} width={42} />
        {isLoading && (
          <>
            <ContentLoader
              className='skeleton-loading-form xs-block md-hidden'
              {...contentLoaderProps}
              height={100}
              width={300}>
              <rect x='0' y='0' rx='0' ry='0' width='300' height='100' />
            </ContentLoader>
            <ContentLoader
              className='skeleton-loading-form xs-hidden md-block'
              {...contentLoaderProps}
              height={50}
              width={600}>
              <rect x='15' y='0' rx='0' ry='0' width='600' height='100' />
            </ContentLoader>
          </>
        )}
        {!isSuccess && !isLoading && (
          <>
            <Styled.CtaTextContainer>
              <label htmlFor='emailAddressInput'>
                <Styled.Header>Sign up for £5 off when you spend £50</Styled.Header>
              </label>
              <Styled.EligibilityStyles>
                Open to customers not already signed-up to Habitat marketing.&nbsp;
                <a href='https://www.habitat.co.uk/help/terms-and-conditions/' rel='noreferrer' target='_blank'>
                  T&Cs apply.
                </a>
              </Styled.EligibilityStyles>
            </Styled.CtaTextContainer>

            <Styled.Form
              className={`${isInvalidEmail ? 'has-error' : ''}`}
              action='#'
              method='post'
              name='emailForm'
              data-test='email-offers-form'>
              <Styled.Input
                placeholder='Email address'
                type='email'
                id='emailAddressInput'
                name='emailAddress'
                required
                ref={emailInputRef}
                data-test='email-offers-input'
              />
              <Styled.Submit onClick={(event) => submitEmail(event)} data-test='email-offers-button'>
                Submit
              </Styled.Submit>
            </Styled.Form>
          </>
        )}
        {isSuccess && !isLoading && (
          <>
            <Styled.Header>All Done!</Styled.Header>
            <Styled.SubText data-test='email-offers-success'>
              Your discount code will be delivered straight to your inbox
            </Styled.SubText>
          </>
        )}
      </Styled.FormWrapper>
      {isInvalidEmail && (
        <Styled.Error kind='error' useSentry={false} data-test='email-offers-error' isCloseable>
          Please enter a valid email address
        </Styled.Error>
      )}

      <div className='container'>
        <Styled.LegalText>
          By post, email, text message, telephone and other electronic means. For more information on how we use your
          personal details please refer to our&nbsp;
          <a href='https://privacy-hub.sainsburys.co.uk/privacy-policy/' rel='noreferrer' target='_blank'>
            Privacy Policy
          </a>
          &nbsp;and&nbsp;
          <a href='https://privacy-hub.sainsburys.co.uk/cookie-policy/' rel='noreferrer' target='_blank'>
            Cookie Policy
          </a>
        </Styled.LegalText>
      </div>
    </>
  )
}

export default EmailForm
