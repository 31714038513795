const themeContract = {
  argos: {
    wrapper: {
      background: 'lighter',
      padding: '25px 0',
      borderTopStyle: 'none',
    },
    button: {
      background: 'white',
      color: 'black',
      borderColor: 'medium',
    },
  },
  habitat: {
    wrapper: {
      background: 'white',
      padding: '25px 0 0 0',
      borderTopStyle: 'solid',
    },
    button: {
      background: 'black',
      color: 'white',
      borderColor: 'black',
    },
  },
}

// eslint-disable-next-line import/prefer-default-export
export { themeContract }
