// GLOBAL DEFAULTS
export const LOAD_APP = 'LOAD_APP'
export const DATA_ALREADY_LOADED = 'DATA_ALREADY_LOADED'
export const RESET_DATA = 'RESET_DATA'

// CMS ACTIONS
export const LOAD_CMS_DATA = 'LOAD_CMS_DATA'
export const LOAD_CMS_DATA_REQUEST = 'LOAD_CMS_DATA_REQUEST'
export const LOAD_CMS_DATA_SUCCESS = 'LOAD_CMS_DATA_SUCCESS'
export const LOAD_CMS_DATA_FAILURE = 'LOAD_CMS_DATA_FAILURE'
export const RESET_CMS_DATA = 'RESET_CMS_DATA'

// HOMEPAGE ACTIONS
export const LOAD_HOMEPAGE_DATA = 'LOAD_HOMEPAGE_DATA'
export const LOAD_HOMEPAGE_DATA_REQUEST = 'LOAD_HOMEPAGE_DATA_REQUEST'
export const LOAD_HOMEPAGE_DATA_SUCCESS = 'LOAD_HOMEPAGE_DATA_SUCCESS'
export const LOAD_HOMEPAGE_DATA_FAILURE = 'LOAD_HOMEPAGE_DATA_FAILURE'
export const RESET_HOMEPAGE_DATA = 'RESET_HOMEPAGE_DATA'

// REVIEWS
export const LOAD_REVIEWS_REQUEST = 'LOAD_REVIEWS_REQUEST'

// TAXONOMY
export const LOAD_TAXONOMY = 'LOAD_TAXONOMY'
export const LOAD_TAXONOMY_SUCCESS = 'LOAD_TAXONOMY_SUCCESS'
export const LOAD_TAXONOMY_FAILURE = 'LOAD_TAXONOMY_FAILURE'
