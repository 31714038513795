// currently running cookies here
export const ARGOS_CARD_COOKIE = 'Argos_Card_Test_Group_1'
export const ARGOS_CARD_COOKIE_A = 'a'
export const ARGOS_CARD_COOKIE_B = 'b'
export const ARGOS_CARD_DEFAULT = ARGOS_CARD_COOKIE_A

export const HOMEPAGE_COOKIE_NAME = 'Content_Test_Group_1'
export const HOMEPAGE_COOKIE_CONTROL_VALUE = '1'
export const HOMEPAGE_COOKIE_VARIANT_VALUE = '2'
export const HOMEPAGE_COOKIE_DEFAULT = HOMEPAGE_COOKIE_CONTROL_VALUE

export const getVariantCookies = (reqCookieArr = []) => {
  const allVariantTests = []

  reqCookieArr.forEach((test) => {
    const { testName, testVal } = test
    if (testName === ARGOS_CARD_COOKIE) {
      if (ARGOS_CARD_COOKIE_A || ARGOS_CARD_COOKIE_B) allVariantTests.push({ testName: ARGOS_CARD_COOKIE, testVal })
      else allVariantTests.push({ testName: ARGOS_CARD_COOKIE, testVal: ARGOS_CARD_DEFAULT })
    }
    if (testName === HOMEPAGE_COOKIE_NAME) {
      if (HOMEPAGE_COOKIE_CONTROL_VALUE || HOMEPAGE_COOKIE_VARIANT_VALUE)
        allVariantTests.push({ testName: HOMEPAGE_COOKIE_NAME, testVal })
      else allVariantTests.push({ testName: HOMEPAGE_COOKIE_NAME, testVal: HOMEPAGE_COOKIE_DEFAULT })
    }
  })
  return allVariantTests
}

export const isHomepageTestVariant = (variantTests = []) => {
  const homepageCookieValue = variantTests.find((test) => test.testName === HOMEPAGE_COOKIE_NAME)?.testVal
  return homepageCookieValue === HOMEPAGE_COOKIE_VARIANT_VALUE
}
