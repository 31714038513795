const ensureStructure = (window) => {
  window.digitalData = window.digitalData || {}
  window.digitalData.page = window.digitalData.page || {}
  window.digitalData.page.attributes = window.digitalData.page.attributes || {}
  window.digitalData.page.attributes.recommendations = window.digitalData.page.attributes.recommendations || []
  window.digitalData.page.clickorigin = window.digitalData.page.clickorigin || {}
  window.digitalData.event = window.digitalData.event || []
  window.digitalData.event.eventInfo = window.digitalData.event.eventInfo || []
  window.digitalData.track = window.digitalData.track || (() => {})
}

const promoCardLayoutBreakdown = {
  '4': {
    '1': [0, 4],
    '2': [4, 8],
    '3': [8, 12],
    '4': [12, 16],
    '5': [16, 20],
  },
  '3': {
    '1': [0, 3],
    '2': [3, 6],
    '3': [6, 9],
    '4': [9, 12],
    '5': [12, 15],
    '6': [15, 18],
    '7': [18, 21],
  },
  '2': {
    '1': [0, 2],
    '2': [2, 4],
    '3': [4, 6],
    '4': [6, 8],
    '5': [8, 10],
    '6': [10, 12],
    '7': [12, 14],
    '8': [14, 16],
    '9': [16, 18],
    '10': [18, 20],
  },
}

const getRandomString = (length = 5) => {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  return Array(length)
    .fill()
    .reduce((string) => {
      return string.concat(characters.charAt(Math.floor(Math.random() * characters.length)))
    }, '')
}

export { ensureStructure, promoCardLayoutBreakdown, getRandomString }
