import {
  LOAD_CMS_DATA,
  LOAD_CMS_DATA_SUCCESS,
  LOAD_CMS_DATA_FAILURE,
  LOAD_CMS_DATA_REQUEST,
  RESET_CMS_DATA,
} from './actionTypes'

export const loadCmsData = ({ location, timestamp, resetData, historyData, variantTests }) => ({
  type: LOAD_CMS_DATA,
  location,
  timestamp,
  resetData,
  historyData,
  variantTests,
})

export const loadCmsDataRequest = () => ({
  type: LOAD_CMS_DATA_REQUEST,
})

export const loadCmsDataSuccess = (cmsResponse, location) => ({
  type: LOAD_CMS_DATA_SUCCESS,
  cmsResponse,
  location,
})

export const loadCmsDataFailure = (error) => ({
  type: LOAD_CMS_DATA_FAILURE,
  error,
})

export const resetCmsData = () => ({
  type: RESET_CMS_DATA,
})
