import styled from 'styled-components'
import { Alert } from '@sainsburys-tech/bolt'
import { themeContract } from './EmailForm.theme.contract'

const FormWrapper = styled.div`
  background: ${(props) => props.theme.color.monochrome[themeContract[props.theme.channel].wrapper.background]};
  border-top-width: 1px;
  border-top-style: ${(props) => themeContract[props.theme.channel].wrapper.borderTopStyle};
  border-top-color: ${(props) => props.theme.color.monochrome.light};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: ${(props) => themeContract[props.theme.channel].wrapper.padding};
  flex-direction: column;

  margin: 0 0 15px;
  width: 100%;
  @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
    flex-direction: row;
    margin: 0 auto 15px;
  }
`

const Header = styled.h4`
  && {
    margin: 5px;

    @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
      margin: 0 25px;
    }
  }
`
const Form = styled.form`
  position: relative;
`

const Input = styled.input`
  border: 1px solid ${(props) => props.theme.color.monochrome.base};
  border-radius: 3px;
  height: 44px;
  padding: 8px 80px 8px 16px;
  width: 300px;
  font-size: 1rem;
`

const Submit = styled.button`
  position: absolute;
  border: 1px solid ${(props) => props.theme.color.monochrome[themeContract[props.theme.channel].button.borderColor]};
  color: ${(props) => props.theme.color.monochrome[themeContract[props.theme.channel].button.color]};
  font-weight: bold;
  background: ${(props) => props.theme.color.monochrome[themeContract[props.theme.channel].button.background]};
  height: 44px;
  width: 80px;
  border-radius: 0 3px 3px 0;
  right: 0;
  top: 0;
  font-size: 1rem;
`

const Error = styled(Alert)`
  max-width: 400px;
  margin: 0 auto 15px;
`

const SubText = styled.p`
  && {
    margin: 0;
  }
`

const CtaTextContainer = styled.div`
  margin: 0px 24px;
  margin-bottom: 15px;

  @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
    margin-bottom: 8px;
  }
}
`

const EligibilityStyles = styled.small`
  text-align: center;
`

const LegalText = styled.small`
  text-align: center;
  margin-bottom: 25px;
`
export { FormWrapper, Header, Form, Input, Submit, Error, SubText, CtaTextContainer, EligibilityStyles, LegalText }
