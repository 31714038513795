import { gethostPath } from '../helpers/ContentHelper'

async function taxonomyApi() {
  const url = `${gethostPath()}meganav.json`
  const res = await fetch(url)
  const taxonomy = await res.json()

  return taxonomy.body.data
}

export default taxonomyApi
