import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'

const { Provider, Consumer } = createContext()

const ScrollPositionProvider = ({ children }) => {
  const [scrollPosition, setPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const getPosition = () => ({
      x: 0,
      y: window.pageYOffset,
    })

    const handleScroll = throttle(() => {
      setPosition(getPosition())
    }, 200)
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return <Provider value={{ scrollPosition }}>{children}</Provider>
}

ScrollPositionProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { ScrollPositionProvider }

export default Consumer
