import { canUseDom } from '@sainsburys-tech/boltui-utils'

import {
  LOAD_HOMEPAGE_DATA_REQUEST,
  LOAD_HOMEPAGE_DATA_SUCCESS,
  LOAD_HOMEPAGE_DATA_FAILURE,
  RESET_HOMEPAGE_DATA,
} from '../actions/actionTypes'

const homepageInitialState = {
  homepageData: [],
  isLoading: false,
  hasError: false,
  historyData: {},
}

export default (state = homepageInitialState, action = {}) => {
  switch (action.type) {
    case LOAD_HOMEPAGE_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    }

    case RESET_HOMEPAGE_DATA: {
      const { historyData } = state

      if (state.homepageData && state.homepageData.resettingData !== true && canUseDom()) {
        Object.assign(historyData, state.homepageData)
      }

      return {
        ...state,
        historyData,
        homepageData: { resettingData: true },
        isLoading: true,
        hasError: false,
      }
    }

    case LOAD_HOMEPAGE_DATA_SUCCESS: {
      const { homepageResponse } = action
      return {
        ...state,
        isLoading: false,
        hasError: false,
        homepageData: homepageResponse.data,
      }
    }

    case LOAD_HOMEPAGE_DATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    }
    default: {
      return state
    }
  }
}

export const getData = (state) => state.app.homepageData
export const getLoading = (state) => state.app.isLoading
