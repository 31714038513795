import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { pushEventAndTrack } from '../../helpers/TaggingSerializer'

import * as Styled from './StoreLocator.styles'

const StoreLocator = ({ className = '' }) => {
  const searchInputRef = useRef(null)
  const [validInput, isValidInput] = useState(true)

  const setInvalidInput = () => {
    searchInputRef.current.focus()
    isValidInput(false)
    setTimeout(() => isValidInput(true), 5000)
  }

  const trackClick = () => {
    const searchString = searchInputRef.current.value
    pushEventAndTrack({
      eventInfo: {
        eventName: 'hp_storeLocatorWidgetSearch',
        eventAction: 'Store Locator Widget Search',
      },
      attributes: {
        searchString,
      },
    })
  }

  const searchForStores = (event) => {
    event.preventDefault()
    const searchString = searchInputRef.current.value
    if (!searchString.length) return setInvalidInput()
    window.location.href = `//www.argos.co.uk/stores/search/${encodeURI(searchString)}`
    return true
  }

  return (
    <Styled.StoreLocator className={className} data-test='store-locator'>
      <Styled.Wrapper>
        <label htmlFor='searchbox'>
          <Styled.StoreHeading>Find your nearest store</Styled.StoreHeading>
        </label>

        <Styled.StoreForm>
          <Styled.StoreInput
            type='text'
            placeholder='My town or postcode is...'
            id='searchbox'
            name='search'
            aria-label='My town or postcode is...'
            data-test='store-locator-input'
            ref={searchInputRef}
          />

          <Styled.StoreButton
            data-test='store-locator-button'
            onClick={(event) => {
              trackClick(event)
              searchForStores(event)
            }}>
            {`
            Let's go!
            `}
          </Styled.StoreButton>
        </Styled.StoreForm>
      </Styled.Wrapper>
      {!validInput && (
        <Styled.StoreError data-test='store-locator-error' kind='error' isCloseable useSentry={false}>
          Please enter a valid town or postcode
        </Styled.StoreError>
      )}
    </Styled.StoreLocator>
  )
}

StoreLocator.propTypes = {
  className: PropTypes.string,
}

export default StoreLocator
