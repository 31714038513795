import { createGlobalStyle } from 'styled-components'

const AppGlobalStyles = createGlobalStyle`
  // Global page structure
  code {
    background: ${(props) => props.theme.color.monochrome.lighter};
    color: ${(props) => props.theme.color.monochrome.black};
    padding: 5px;
  }

  .h2,
  .h3 {
    margin-top: 0;
  }

  hr {
    width: 100%;
    border: 0;
    height: 1px;
    margin: 5px 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(169, 169, 169, 0.75), rgba(0, 0, 0, 0));
  }
`
export default AppGlobalStyles
