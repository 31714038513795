import styled, { css } from 'styled-components'
import { Image } from '@sainsburys-tech/bolt'

const Container = styled.div`
  display: -ms-grid;
  display: grid;
  grid-gap: 10px;
  overflow: hidden;
  width: 100%;

  @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }
  ${(props) =>
    props.columns === 3 &&
    css`
      margin-bottom: 20px;
      grid-template-columns: 1fr;
      -ms-grid-columns: 1fr;
      -ms-grid-rows: 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        grid-template-columns: 1fr 1fr 1fr;
        -ms-grid-columns: 1fr 10px 1fr 10px 1fr;
        -ms-grid-rows: 1fr 10px 1fr 10px;
      }
    `}
  ${(props) =>
    props.columns === 4 &&
    css`
      margin-bottom: 40px;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr 1fr;
      -ms-grid-columns: 1fr 10px 1fr;
      -ms-grid-rows: 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr 10px 1fr;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
        -ms-grid-rows: 1fr 10px 1fr 10px 66px 10px 1fr 10px;
      }
    `}
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  display: block;
`
const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  &:before {
    content: '';
    padding-top: 100%;
    display: block;

    @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
      padding-top: 90%;
    }
  }

  ${StyledImage} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

const Component = styled.a`
  display: block;

  ${(props) =>
    props.index === 1 &&
    css`
      ${ImageContainer}:before {
        padding-top: 90%;
      }
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      -ms-grid-row: 1;
      -ms-grid-row-span: 3;

      grid-column: 1 / span 2;
      grid-row: 1 / span 2;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;

        -ms-grid-column: 1;
        -ms-grid-column-span: 3;
        -ms-grid-row: 1;
        -ms-grid-row-span: 3;
      }
    `}

  ${(props) =>
    props.index === 2 &&
    css`
      ${ImageContainer}:before {
        padding-top: 39.3%;
        @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
          padding-top: 34.5%;
        }
      }

      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      -ms-grid-row: 5;

      grid-column: 1 / span 2;
      grid-row: 3;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        grid-column: 3 / span 2;
        grid-row: 1;

        -ms-grid-column: 5;
        -ms-grid-column-span: 5;
        -ms-grid-row: 1;
      }
    `}

  ${(props) =>
    props.index === 3 &&
    css`
      -ms-grid-column: 1;
      -ms-grid-row: 7;

      grid-column: 1;
      grid-row: 4;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;

        grid-column: 3;
        grid-row: 2;
      }
    `}

  ${(props) =>
    props.index === 4 &&
    css`
      -ms-grid-column: 3;
      -ms-grid-row: 7;

      grid-column: 2;
      grid-row: 4;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        -ms-grid-column: 7;
        -ms-grid-column-span: 6;
        -ms-grid-row: 3;

        grid-column: 4;
        grid-row: 2;
      }
    `}
  // proposition text banner line

  ${(props) =>
    props.index === 5 &&
    css`
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      -ms-grid-row: 9;

      grid-column: 1 / span 2;
      grid-row: 5;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        -ms-grid-column: 1;
        -ms-grid-column-span: 7;
        -ms-grid-row: 5;

        grid-column: 1 / span 4;
        grid-row: 3;
      }
    `}

  // SECOND BLOCK

  ${(props) =>
    props.index === 6 &&
    css`
      ${ImageContainer}:before {
        padding-top: 90%;
      }
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      -ms-grid-row: 11;
      -ms-grid-row-span: 3;

      grid-column: 1 / span 2;
      grid-row: 6 / span 2;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;
        -ms-grid-row: 7;
        -ms-grid-row-span: 3;

        grid-column: 1 / span 2;
        grid-row: 4 / span 2;
      }
    `}

  ${(props) =>
    props.index === 7 &&
    css`
      -ms-grid-column: 1;
      -ms-grid-row: 15;

      grid-column: 1;
      grid-row: 9;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        -ms-grid-column: 5;
        -ms-grid-row: 7;

        grid-column: 3;
        grid-row: 4;
      }
    `}

  ${(props) =>
    props.index === 8 &&
    css`
      -ms-grid-column: 3;
      -ms-grid-row: 15;

      grid-column: 2;
      grid-row: 9;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        -ms-grid-column: 7;
        -ms-grid-row: 7;

        grid-column: 4;
        grid-row: 4;
      }
    `}

  ${(props) =>
    props.index === 9 &&
    css`
      // wide with text
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      -ms-grid-row: 17;

      grid-column: 1 / span 2;
      grid-row: 10;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        -ms-grid-column: 5;
        -ms-grid-column-span: 3;
        -ms-grid-row: 9;

        grid-column: 3 / span 2;
        grid-row: 5;
      }
    `}
  // THIRD BLOCK

  ${(props) =>
    props.index === 10 &&
    css`
      -ms-grid-column: 1;
      -ms-grid-row: 1;

      grid-column: 1;
      grid-row: 1;
    `}

  ${(props) =>
    props.index === 11 &&
    css`
      -ms-grid-column: 1;
      -ms-grid-row: 3;

      grid-column: 1;
      grid-row: 2;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;

        grid-column: 2;
        grid-row: 1;
      }
    `}

  ${(props) =>
    props.index === 12 &&
    css`
      -ms-grid-column: 1;
      -ms-grid-row: 5;

      grid-column: 1;
      grid-row: 3;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        -ms-grid-column: 5;
        -ms-grid-column-span: 2;
        -ms-grid-row: 1;

        grid-column: 3;
        grid-row: 1;
      }
    `}

  ${(props) =>
    props.index === 13 &&
    css`
      -ms-grid-column: 1;
      -ms-grid-row: 7;

      grid-column: 1;
      grid-row: 4;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;

        grid-column: 1;
        grid-row: 2;
      }
    `}

  ${(props) =>
    props.index === 14 &&
    css`
      -ms-grid-column: 1;
      -ms-grid-row: 9;

      grid-column: 1;
      grid-row: 5;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;

        grid-column: 2;
        grid-row: 2;
      }
    `}

  ${(props) =>
    props.index === 15 &&
    css`
      -ms-grid-column: 1;
      -ms-grid-row: 11;

      grid-column: 1;
      grid-row: 6;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;

        grid-column: 3;
        grid-row: 2;
      }
    `}
`

const TextComponent = styled.div`
  position: relative;
  &::before {
    padding-top: 39.3%;

    @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
      padding-top: 49%;
    }
    position: relative;
    content: '';
    display: block;
  }
`

const TextContent = styled.div`
  border-style: solid;
  border-color: ${(props) => props.theme.color.monochrome.base};
  border-width: 1px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
    border-width: 1px;
  }
`
const CTA = styled.strong`
  border-bottom: 2px solid ${(props) => props.theme.color.monochrome.darker};
`
const Proposition = styled.span`
  display: none;

  @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    ${(props) =>
      props.hidden &&
      css`
        display: none;
      `}
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.color.monochrome.base};
    z-index: 0;
    left: 0;
    right: 0;
  }
`
const PropositionText = styled.strong`
  height: 66px;
  position: relative;
  background: ${(props) => props.theme.color.monochrome.white};
  padding: 25px 15px;
  z-index: 1;
  margin: 0;
`

export {
  Container,
  ImageContainer,
  StyledImage,
  Component,
  TextComponent,
  TextContent,
  CTA,
  Proposition,
  PropositionText,
}
