import styled from 'styled-components'
import { Slider } from '@sainsburys-tech/bolt'

const PromotionSlider = styled(Slider)`
  margin-bottom: 10px;
  margin-top: 20px;
  display: block;
  .promotion-panel-slide {
    flex-direction: column;

    @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].sm}) {
      flex-direction: row;
    }
  }
  .btn-cta {
    display: none;
  }
`

const SkeletonWrapper = styled.div`
  max-height: 400px;
  overflow: hidden;
  div:nth-child(3),
  div:nth-child(4) {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
    div:nth-child(3) {
      display: block;
    }
  }

  @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].lg}) {
    div:nth-child(4) {
      display: block;
    }
  }
`

const Heading = styled.h2`
  && {
    margin-bottom: 15px;
  }
`
export { PromotionSlider, SkeletonWrapper, Heading }
