import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import homepageApp from './homepageAppReducer'
import cmsApp from './cmsAppReducer'
import reviewsApp from './reviewsAppReducer'
import taxonomy from './taxonomyReducer'

export default (history) => {
  const router = history ? connectRouter(history) : {}
  return combineReducers({
    router,
    homepageApp,
    cmsApp,
    reviewsApp,
    taxonomy,
  })
}
