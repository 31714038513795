import { LOAD_TAXONOMY, LOAD_TAXONOMY_SUCCESS, LOAD_TAXONOMY_FAILURE } from './actionTypes'

export const loadTaxonomy = () => ({
  type: LOAD_TAXONOMY,
})

export const loadTaxonomySuccess = (payload) => ({
  type: LOAD_TAXONOMY_SUCCESS,
  payload,
})

export const loadTaxonomyFailure = (error) => ({
  type: LOAD_TAXONOMY_FAILURE,
  error,
})
