import 'isomorphic-unfetch'
import { call, put, takeLatest } from 'redux-saga/effects'
import { LOAD_TAXONOMY } from '../actions/actionTypes'
import { loadTaxonomySuccess, loadTaxonomyFailure } from '../actions/taxonomyActions'
import taxonomyApi from '../api/taxonomyApi'

export function* loadTaxonomySaga() {
  try {
    const taxonomy = yield call(taxonomyApi)

    yield put(loadTaxonomySuccess(taxonomy))
  } catch (err) {
    yield put(loadTaxonomyFailure(err))
  }
}

export function* watchLoadTaxonomy() {
  yield takeLatest(LOAD_TAXONOMY, loadTaxonomySaga)
}
