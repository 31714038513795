import LazyHydrate from 'react-lazy-hydration'
import React from 'react'

import { ErrorBoundary, Themes } from '@sainsburys-tech/bolt'
import { ArgosVocab, HabitatVocab } from '@sainsburys-tech/bolt-vocab-provider'

import FeaturedProducts from '../components/FeaturedProducts/FeaturedProducts'
import StoreLocator from '../components/StoreLocator/StoreLocator'

export const isArgos = () => {
  return process.env.BRAND === 'argos'
}

export const isHabitat = () => {
  return process.env.BRAND === 'habitat'
}

export const getBrandName = () => {
  if (!process.env.BRAND) return ''
  const brandName = `${process.env.BRAND?.charAt(0).toUpperCase()}${process.env.BRAND?.slice(1)}`
  return brandName
}

export const getBoltClass = () => {
  return {
    argos: 'v2',
    habitat: 'habitat',
  }[process.env.BRAND]
}

export const getBoltTheme = () => {
  return {
    argos: Themes.ArgosTheme,
    habitat: Themes.HabitatTheme,
  }[process.env.BRAND]
}

export const getBoltVocab = () => {
  return {
    argos: ArgosVocab,
    habitat: HabitatVocab,
  }[process.env.BRAND]
}

const homepageComponents = {
  argos: (
    <>
      <LazyHydrate whenIdle>
        <ErrorBoundary>
          <FeaturedProducts />
        </ErrorBoundary>
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <ErrorBoundary>
          <StoreLocator />
        </ErrorBoundary>
      </LazyHydrate>
    </>
  ),
}

export const getHomepageComponents = () => {
  return homepageComponents[process.env.BRAND] || null
}
