import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import BoltFooter from '@sainsburys-tech/bolt-footer'
import { BackToTop } from '@sainsburys-tech/bolt'

const Footer = ({ brand }) => {
  const [serverSideFooter, setFooterState] = useState(true)

  useEffect(() => {
    setFooterState(false)
  })

  return serverSideFooter ? (
    <BoltFooter brand={brand} />
  ) : (
    <div>
      <div data-test='back-to-top'>
        <BackToTop />
      </div>
      <BoltFooter brand={brand} />
    </div>
  )
}

Footer.propTypes = {
  brand: PropTypes.string.isRequired,
}

export default Footer
