import React from 'react'
import { GetComponent } from '@sainsburys-tech/bolt-cms-components'
import { ErrorBoundary } from '@sainsburys-tech/bolt'
import { canUseDom } from '@sainsburys-tech/boltui-utils'
import { isHomepagePath, isArgosHomepagePath, isMagnoliaApp, isReviewsPath } from './pathCheck'
import { isArgos } from './brandHelper'

export const breakpointPx = { MOBILE: 481, SMALL: 576, MEDIUM: 768, LARGE: 993 }

export const getContentAreas = (areas, pathName, onCmsClick, onAnchorEntry, anchorNavigation, scrollPosition) => {
  const contentComponents = []
  const validAreas = !!areas && areas.length
  if (validAreas) {
    areas.forEach((area) => {
      const validAreaAttr = !!area.attributes && area.attributes.length
      if (validAreaAttr) {
        const areaComponents = []
        area.attributes.forEach((currentArea, index) => {
          if (currentArea.type) {
            let contentProps
            const baseProps = {
              ...currentArea,
              ...scrollPosition,
              onLinkClick: onCmsClick,
            }
            if (currentArea.type === 'M017' && anchorNavigation) {
              contentProps = {
                ...baseProps,
                ...anchorNavigation,
                onAnchorEntry,
              }
            } else {
              contentProps = { ...baseProps }
            }
            areaComponents.push(
              <ErrorBoundary key={area.id + currentArea.type + index}>
                <GetComponent {...{ ...currentArea, ...contentProps }} />
              </ErrorBoundary>,
            )
          }
        })
        // Area A on Argos homepage needs to be full width
        const fullWidthClass = isArgosHomepagePath(pathName) && area.id === 'A' ? 'full-width' : null
        contentComponents.push(
          <div data-area={`content-area-${area.id}`} className={fullWidthClass}>
            {areaComponents}
          </div>,
        )
      }
    })
  }
  return contentComponents
}

export const validAjaxLink = (url) => {
  // HOMEPAGE
  const homepageRelativePath = '/'
  const isRelativeHomepage = url.split('?')[0] === homepageRelativePath

  // EVENTS
  const eventsAbsoluteRegex = /(?:argos.co.uk|eu-west-1.production.deveng.systems|eu-west-1.staging.deveng.systems)\/events\//
  const eventsRelativePath = '/events/'
  const isRelativeEvent = url.indexOf(eventsRelativePath) === 0
  const isAbsoluteEvent = !!url.match(eventsAbsoluteRegex)

  // FEATURES
  const featuresAbsoluteRegex = /(?:argos.co.uk|eu-west-1.production.deveng.systems|eu-west-1.staging.deveng.systems)\/features\//
  const featuresRelativePath = '/features/'
  const isRelativeFeature = url.indexOf(featuresRelativePath) === 0
  const isAbsoluteFeature = !!url.match(featuresAbsoluteRegex)

  // BRANDS
  const brandsAbsoluteRegex = /(?:argos.co.uk|eu-west-1.production.deveng.systems|eu-west-1.staging.deveng.systems)\/brands\//
  const brandsRelativePath = '/brands/'
  const isRelativeBrands = url.indexOf(brandsRelativePath) === 0
  const isAbsoluteBrands = !!url.match(brandsAbsoluteRegex)

  let cleanUrl = ''
  if (url.includes('.html')) cleanUrl = url.replace(/.html/, '')
  else cleanUrl = url

  if (isAbsoluteEvent) return eventsRelativePath + cleanUrl.split(eventsRelativePath).pop()
  if (isAbsoluteFeature) return featuresRelativePath + cleanUrl.split(featuresRelativePath).pop()
  if (isAbsoluteBrands) return brandsRelativePath + cleanUrl.split(brandsRelativePath).pop()
  if (isRelativeHomepage || isRelativeEvent || isRelativeFeature || isRelativeBrands) return cleanUrl
  return null
}

export const getUsedComponentTypes = (data) => {
  if (!data.length) return {}
  const areas = []
  const addToArray = (areaType) => {
    if (areaType.type.startsWith('GL')) {
      areas.push({
        grouping: {
          type: areaType.type,
          desc: areaType.desc,
        },
      })
    }
    if (areaType.type.startsWith('M')) {
      if (areaType.included) {
        areas.push({
          grouping: {
            type: areaType.included.type,
            desc: areaType.included.desc,
          },
        })
      }
      areas.push({
        type: areaType.type,
        desc: areaType.desc,
        ...(areaType.type === 'M010' && areaType.attributes && areaType.attributes.productId
          ? { id: areaType.attributes.productId }
          : {}),
      })
    }
  }
  const recursive = (area) => {
    if (area.included && area.included.included) {
      area.included.included.forEach((included) => {
        /* istanbul ignore next */
        if (included.type) addToArray(included)
      })
    }
    if (Array.isArray(area)) {
      area.forEach((attribute) => {
        if (attribute.type) addToArray(attribute)
      })
    }
    Object.keys(area).forEach((key) => {
      if (key === 'type') addToArray(area)
      if (Array.isArray(area[key])) recursive(area[key])
    })
  }
  data.map((area) => {
    if (area.attributes.length) area.attributes.map(recursive)
    return null
  })
  return areas
}

export const getSiteSection = (location) => {
  const path = location
  if (isReviewsPath(path)) return 'cgc'
  if (isHomepagePath(path)) return 'home'
  if (isMagnoliaApp(path)) return location.split('/')[1]
  return 'error-page'
}

export const getTemplateLevelAttrs = (response) => {
  if (response && response.template && response.template.data.length) {
    if (response.template.data[0].type === 'template') return response.template.data[0].attributes
    const correctIndex = response.template.data.findIndex((area) => area.type === 'template')
    return response.template.data[correctIndex] && response.template.data[correctIndex].attributes
  }
  return {}
}

export const gethostPath = () => {
  if (!canUseDom()) {
    return process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'http://localhost:8080/'
  }
  return `${window.location.origin}/`
}

// New templates (all Habitat responses + Argos homepage) have different structure so format it to old response structure which the code handles
export const formatToTemplateResponse = (response) => {
  return {
    meta: response?.meta,
    template: {
      data: [
        {
          type: response?.type,
          id: response?.id,
          desc: response?.desc,
          attributes: response?.attributes,
        },
        ...response?.data,
      ],
    },
  }
}

export const getCMSAreas = (cmsData, pathName) => {
  const isArgosHomepage = isArgos() && isHomepagePath(pathName)
  const { template: { data = [] } = {} } = cmsData

  // Argos homepage contains hardcoded components which are in-between CMS content
  // Take last area and position after hardcoded components as footerContentAreas
  return {
    bodyContentAreas: isArgosHomepage ? data.slice(0, -1) : data,
    footerContentAreas: isArgosHomepage ? [data[data.length - 1]] : [],
  }
}

export const propbarRoutes = (route) => [
  {
    match: '/features/delivery-collection-guide?tag=ar:propbar:samedaydelivery',
    handler: () => route('/features/delivery-collection-guide?tag=ar:propbar:samedaydelivery'),
  },
  {
    match: '/features/delivery-collection-guide?tag=ar:propbar:fasterinstorecollectionforfree',
    handler: () => route('/features/delivery-collection-guide?tag=ar:propbar:fasterinstorecollectionforfree'),
  },
  {
    match: '/help/argos-card/?tag=ar:propbar:argoscard',
    handler: () => route('/help/argos-card/?tag=ar:propbar:argoscard'),
  },
]

export const fallbackMeta = {
  argos: {
    title: 'Argos | Same Day Delivery or Faster In-Store Collection',
    description:
      'Get what you want today with Fast Track same day delivery 7 days a week or faster in-store collection for free',
  },
  habitat: {
    title: 'Habitat | Sofas, furniture, lighting & home accessories',
    description: 'Shop the Habitat range and select your delivery date and time. Shop online today.',
  },
}[process.env.BRAND || 'argos']
