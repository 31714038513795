import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { HeaderFetcher } from '@sainsburys-tech/bolt'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { validAjaxLink, getSiteSection } from '../../helpers/ContentHelper'
import { ensureStructure } from '../../helpers/TaggingHelper'
import { isHomepagePath, isMagnoliaApp } from '../../helpers/pathCheck'
import TaggingSerializer, { getSatelliteTrackingEvent } from '../../helpers/TaggingSerializer'

const Header = ({ history, cmsData }) => {
  const [userInfoState, setUserInfoState] = useState({})
  const [clickOrigin, setClickOrigin] = useState('')

  const handleClick = (linkUrl) => {
    const ajaxLinkUrl = validAjaxLink(linkUrl)
    const pathName = history.location.pathname

    if (!ajaxLinkUrl) {
      window.location.href = linkUrl
    } else {
      ensureStructure(window)
      const sourceLocation = getSiteSection(pathName)
      const isHomepageLink = isHomepagePath(linkUrl.split('?')[0])

      const clickOriginLinkUrl = linkUrl && linkUrl.split('clickOrigin=header:')[1]
      const destLocation = isHomepageLink ? 'home' : clickOriginLinkUrl && clickOriginLinkUrl.split(':')[1]
      setClickOrigin(`header:${sourceLocation}:${destLocation}`)

      setTimeout(() => {
        // track function for header clicks
        const eventInfo = {
          eventAction: 'header clickOrigin',
          eventName: 'header_clickOrigin',
        }
        window.digitalData.event.push({ eventInfo })
        window.digitalData.track(eventInfo.eventName)
      })
      if (isHomepageLink) {
        // Replace with correct siteSection in clickOrigin param as the href in the logo doesn't update :(
        const homepageLink = ajaxLinkUrl.replace(/clickOrigin=header:.*?:/, `clickOrigin=header:${sourceLocation}:`)
        history.push(homepageLink)
      } else {
        history.push(ajaxLinkUrl)
      }
    }
  }

  // const handleHomepageClick = (linkUrl) => {
  //   const taggingSerializer = new TaggingSerializer('/')
  //   // Update siteSection for header re-render on homepage route (history.location.pathname does not change on this route)
  //   window.digitalData.page.pageInfo.siteSection = taggingSerializer.getDataObject().page.pageInfo.siteSection
  //   handleClick(linkUrl)
  // }

  const routes = {
    // Disable header logo SPA routing to the homepage as /global-api/cms/ proxy doesn't cache query strings for the variant (yet)
    // homepage: (linkUrl) => handleHomepageClick(linkUrl),
    megaButtons: [
      (linkUrl) => {
        handleClick(linkUrl)
      },
      (linkUrl) => {
        handleClick(linkUrl)
      },
    ],
    'advice-and-inspiration': (linkUrl) => handleClick(linkUrl),
  }

  const updateDigitalDataObject = (userInfo) => {
    const isValidUserState = Object.keys(userInfo).length
    if (!isValidUserState) return

    const pathName = history.location.pathname
    const cmsTemplate = cmsData && cmsData.template
    const taggingSerializer = new TaggingSerializer(pathName, userInfo, cmsTemplate, clickOrigin)
    if (window.digitalData) {
      Object.assign(window.digitalData, taggingSerializer.getDataObject())
      const eventName = taggingSerializer.getTrackingEventName(pathName)
      getSatelliteTrackingEvent(eventName)
    }
  }

  const onUserInfoLoad = (userInfo) => {
    setUserInfoState(userInfo)
  }

  // Update siteSection for header re-render on location change
  useEffect(() => {
    const taggingSerializer = new TaggingSerializer(history.location.pathname)
    window.digitalData.page.pageInfo.siteSection = taggingSerializer.getDataObject().page.pageInfo.siteSection
  }, [history.location.pathname])

  // Update digital data object once user info has loaded
  useEffect(() => {
    updateDigitalDataObject(userInfoState)
  }, [userInfoState])

  // Update digital data object if route has changed via ajax call
  useEffect(() => {
    const pathName = history.location.pathname
    if (!isMagnoliaApp(pathName)) {
      updateDigitalDataObject(userInfoState)
    }
  }, [clickOrigin])

  // Update digital data object for magnolia routes (digitalData needs updated cmsData)
  useEffect(() => {
    const pathName = history.location.pathname
    if (isMagnoliaApp(pathName) && !cmsData?.resettingData) {
      updateDigitalDataObject(userInfoState)
    }
  }, [cmsData])

  return <HeaderFetcher.ArgosHeader routes={routes} onUserInfoLoad={onUserInfoLoad} skipToContentTag='#app-content' />
}

Header.propTypes = {
  history: PropTypes.shape({
    action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
    block: PropTypes.func.isRequired,
    canGo: PropTypes.func,
    createHref: PropTypes.func.isRequired,
    entries: PropTypes.arrayOf(PropTypes.object),
    go: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    goForward: PropTypes.func.isRequired,
    index: PropTypes.number,
    length: PropTypes.number,
    listen: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }),
  cmsData: PropTypes.shape({
    template: PropTypes.string,
    resettingData: PropTypes.bool,
  }),
}

Header.defaultProps = {
  history: {},
  cmsData: {
    template: '',
    resettingData: false,
  },
}

const mapStateToProps = (state) => ({
  cmsData: state?.cmsApp?.cmsData,
})

const mapDispatchToProps = (dispatch) => ({
  historyPush: (route) => dispatch(push(route)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
