import styled, { css } from 'styled-components'
import ContentLoader from 'react-content-loader'

const DefaultLoader = styled(ContentLoader)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const HeroLoader = styled(ContentLoader)`
  width: 100%;
  height: 250px;
  margin-bottom: 60px;
  @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
    height: 350px;
  }
`
const TaxonomyLoader = styled(ContentLoader)`
  width: 214px;
`
const Skeleton = styled.div`
  position: relative;

  ${(props) =>
    props.page === 'cms' &&
    css`
      margin-top: 15px;
    `}
  ${(props) =>
    props.page === 'homepage' &&
    css`
      margin-top: 0;
    `}
`
export { DefaultLoader, HeroLoader, Skeleton, TaxonomyLoader }
